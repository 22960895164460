import React, { useMemo, useState, useRef } from 'react';
import {
  ChakraProvider,
  theme,
  FormControl,
  FormLabel,
  Textarea,
  Container,
  Input,
  Stack,
  Button,
  Box,
} from '@chakra-ui/react';
import { tsvParseRows } from 'd3-dsv';
import { hierarchy } from 'd3-hierarchy';
import { saveAs } from 'file-saver';
import TheChart from './TheChart';
import Dom2Canvas from './dom2canvas';

const getGroupShape = (name, outline) => ({
  name,
  children: [],
  outline,
});

function App() {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const formatted = useMemo(() => {
    if (!text) return [];
    const lines = tsvParseRows(text);
    // console.log(lines)
    if (Number.isNaN(+lines[0]?.[2])) {
      lines.shift();
    }
    let activeMain
    const reduced = lines.reduce((acc, [main, name, value, outline], i) => {
      if (main) {
        activeMain = main
      }
      if (activeMain) {
        acc[activeMain] = acc[activeMain] || getGroupShape(activeMain, outline);
        acc[activeMain].children.push({ name, value });
      }

      return acc;
    }, {});
    return Object.values(reduced);
  }, [text]);

  const [downloading, setDowloading] = useState(false);

  const packData = useMemo(
    () =>
      hierarchy({
        name: title,
        children: formatted,
      }).sum(d => d.value),
    [formatted, title]
  );

  const chartRef = useRef(null);
  const handleDownload = async () => {
    setDowloading(true);
    const ins = new Dom2Canvas(chartRef.current);
    ins.toCanvas(canvas => {
      canvas.toBlob(blob => {
        saveAs(blob, `${title}.png`);
        setDowloading(false);
      });
    });
  };
  // console.log(formatted, packData)
  return (
    <ChakraProvider theme={theme}>
      <Container py="8" maxW="container.lg">
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>輸入標題</FormLabel>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>貼上資料</FormLabel>
            <Textarea value={text} onChange={e => setText(e.target.value)} />
          </FormControl>
          <Box p="3em">
            <TheChart ref={chartRef} data={packData} />
          </Box>
          <Box>
            <Button px="6" onClick={handleDownload} isLoading={downloading}>
              下載
            </Button>
          </Box>
        </Stack>
      </Container>
    </ChakraProvider>
  );
}

export default App;
