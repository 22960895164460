import {
  AspectRatio,
  Circle,
  Flex,
  forwardRef,
  useMergeRefs,
  useToken,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef } from 'react';
import range from 'lodash/range'
import { useMeasure } from 'react-use';
import { pack } from 'd3-hierarchy';

// var div = 360 / 6;
// var radius = 150;
// var parentdiv = document.getElementById('parentdiv');
// var offsetToParentCenter = parseInt(parentdiv.offsetWidth / 2); //assumes parent is square
// var offsetToChildCenter = 20;
// var totalOffset = offsetToParentCenter - offsetToChildCenter;
// for (var i = 1; i <= 6; ++i) {
//   var childdiv = document.createElement('div');
//   childdiv.className = 'div2';
//   childdiv.style.position = 'absolute';
//   var y = Math.sin((div * i) * (Math.PI / 180)) * radius;
//   var x = Math.cos((div * i) * (Math.PI / 180)) * radius;
//   childdiv.style.top = (y + totalOffset).toString() + "px";
//   childdiv.style.left = (x + totalOffset).toString() + "px";
//   parentdiv.appendChild(childdiv);
// }

const r = 150

const TheChart = forwardRef(({ data }, ref) => {
  const svgRef = useRef();
  const [mesureRef, { width }] = useMeasure();
  const grays = useToken('colors', ['gray.400', 'gray.200']);
  const packedData = useMemo(
    () =>
      pack()
        .size([width / 2, width / 2])(data)
        .descendants()
        .slice(1)
    ,[data, width]
  );
  const useData = packedData.filter(d => d.depth === 1)
  // const offsetToParentCenter = useMemo(() => parseInt(svgRef?.current?.offsetWidth / 2), [svgRef?.current?.offsetWidth])

  console.log(packedData);

  return (
    <Circle position={'relative'} size={`${r}px`} bg="gray" key={svgRef}>
      {/* <AspectRatio ref={useMergeRefs(mesureRef, ref)} ratio={1}>
        <svg
          width={width}
          height={width}
          viewBox={[-width / 4, -width / 4, width, width]}
          ref={svgRef}
        >
          {packedData.map((d, i) => d.depth !== 1 && (
            <circle key={i} r={d.r} cx={d.x} cy={d.y} fill={grays[d.depth - 1]} />
          ))}
          {packedData.map((d, i) => d.depth === 1 && (
            <circle key={i} r={d.r / 1.5} cx={d.x} cy={d.y} fill={grays[d.depth - 1]} />
          ))}
        </svg>
      </AspectRatio> */}
      {range(5).map((num) => {
        const deg = 360 / 5;
        const top = Math.sin((deg * (num + 1)) * (Math.PI / 180)) * (r / 2)
        const left = Math.cos((deg * (num + 1)) * (Math.PI / 180)) * (r / 2)
        return (
          <Circle
            top={`${top + r / 2}px`}
            left={`${left + r / 2}px`}
            bg={'gray.200'}
            border="1px solid"
            key={num}
            minW="100px"
            minH="100px"
            size={`${50 * (num + 1)}px`}
            pos="absolute"
            transform={'translate(-50%, -50%)'}
            zIndex={5 - num}
          />
        )
      })}
      <Circle pos="absolute" width="100%" height="100%" bg="white" zIndex={99} />
    </Circle>
  );
});

export default TheChart;
