import domtoimage from 'dom-to-image';

const PIXEL_RATIO = (function () {
  if (typeof window === 'undefined') return 1
  const ctx = document.createElement('canvas').getContext('2d'),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;

  return dpr / bsr;
})();

const setHiDPICanvas = function(canvas, w, h, ratio) {
  if (!ratio) { ratio = PIXEL_RATIO; }
  const can = canvas;
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + 'px';
  can.style.height = h + 'px';
  can.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
}

class Dom2Canvas {
  constructor(dom, w, h) {
    this.dom = dom;
    this.canvas = null;
    this.ctx = null;
    this.width = w
    this.height = h;
    this.data = null;
    // this.injectedStyle = style;
    // if (styleFile) {
    //   fetch(styleFile)
    //     .then(res => res.text())
    //     .then((styleText) => {
    //       this.injectedStyle = styleText + this.injectedStyle

    //       this.loaded = true
    //     });
    // } else {
    //   this.loaded = true
    // }
    this.loaded = true
    this.init();
  }

  init = () => {
    if (!this.width) this.width = this.dom.clientWidth;
    if (!this.height) this.height = this.dom.clientHeight;
    if (!this.loaded) {
      return setTimeout(this.init, 100)
    }

    // 初始化canvas
    this.canvas = document.createElement('canvas');
    setHiDPICanvas(this.canvas, this.width, this.height)
    this.ctx = this.canvas.getContext('2d');
    // 添加一个默认的矩形, 防止导出时, canvas的透明的.
    // this._addBackgroundRect();

    // 添加xmlns, 按照XHTML标准渲染，实现了SVG和XHTML的混合使用
    // this._addNamespace();
  };

  _addNamespace = () => {
    this.dom.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
  }

  toCanvas = (cb, ...moreSVG) => {
    if (this.loaded) {
      domtoimage.toPng(this.dom)
        .then((dataUrl) => {
          const image = new Image();
          image.src = dataUrl;
          image.onload = () => {
            this.ctx.drawImage(image, 0, 0)
            URL.revokeObjectURL(dataUrl);
            Promise.all(moreSVG.map(this.loadSVG)).then(() => {
              if (cb) {
                cb(this.canvas);
              }
            })
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });

    } else {
      setTimeout(() => this.toCanvas(cb, ...moreSVG), 100)
    }
  };

  loadSVG = (svg) => {
    if (!svg) return null
    // hack: instead of a blobURL, if we use a dataURL, chrome seems happy...
    const url = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svg);
    const image = new Image();

    return new Promise((res) => {
      image.onload = () => {
        this.ctx.drawImage(image, 0, 0);
        URL.revokeObjectURL(url);
        res()
      }
      image.src = url;
    })
  }
}

export default Dom2Canvas
